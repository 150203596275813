@import 'src/scss/variables';

.action {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  width: 26px;
  margin: 0px 15px 0px 5px;
  cursor: pointer;
  color: $icon;
}
.download {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  width: 26px;
  margin: 0px 15px 0px 5px;
  cursor: pointer;
}
.link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  width: 26px;
  margin: 0px 15px 0px 5px;
  cursor: pointer;
  color: $hover;
}
.download:hover,
.action:hover {
  color: $hover;
}
.link:hover {
  color: $hover;
}
.circle:hover [data-role='tooltip'] {
  display: block;
}
.action:hover [data-role='tooltip'] {
  display: block;
}
.link:hover [data-role='tooltip'] {
  display: block;
}
