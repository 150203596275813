@import 'scss/common';
@import 'scss/variables';

.grow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: $border;
  border: 1px solid $border;
  font-size: 30px;
  p {
    font-weight: 500;
    font-size: 13px;
    margin: 10px 0px;
  }
}

.features {
  display: flex;
  flex-grow: 1;
  background: #fff;
  margin: 7px 7px 7px 7px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  min-width: calc(100% - 14px);
  max-width: calc(100% - 14px);
  .tabs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    // border-right: 1px solid $border;
    border-left: 1px solid $border;
    min-width: 200px;
    max-width: 200px;
    .tab:first-child {
      border-top: 1px solid $border;
    }
    .tab {
      display: flex;
      align-items: center;
      position: relative;
      flex-grow: 1;
      border-bottom: 1px solid $border;
      padding: 0px 15px;
      cursor: pointer;
      p {
        font-size: 13px;
        line-height: 13px;
        margin: 0px;
      }
    }
    .tab:hover {
      color: $hover;
    }
    .badge {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-radius: 16px;
      top: 3px;
      right: 3px;
      min-width: 16px;
      max-height: 16px;
      min-height: 16px;
      border: 1px solid darken($orange, 15%);
      background: lighten($orange, 35%);
      color: darken($orange, 15%);
      span {
        font-size: 10px;
        line-height: 9px;

        padding: 1px 3px 0px 3px;
        margin: 0px;
      }
    }
    .active {
      color: $hover;
    }
  }
}
.feature {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  border-bottom: 1px solid $border;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 7px;
}

.advertisers {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  border: 1px solid $border;
}

