.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 15px 15px 15px;
}
.flex {
  flex-grow: 1;
}
.cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #62738d;
  margin: 0 15px;
  font-size: 13px;
  font-weight: 500;
  height: 31px;
}
.cancel:hover {
  color: #276ef1;
}
