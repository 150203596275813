$green: #5cb85c;
$orange: #f9a43e;
$blue: #3e96ed;
$silver: #8aa3b0;
$grey: #d6dbdf;
$red: #d9534f;
$purple: #906fb1;
$pink: #e855b6;
$black: #1d1d1d;
$white: #ffffff;

$font: #404244;
$hover: lighten(#e855b6, 10);
$border: #b6bac0;
$menu: #343a46;
$icon: #343a46;
$blue_75: #ecf5fd;
$grey_800: #1f1f1f;
$neutrals_3: #f5f5f5;
$grey_150: #f0f0f0;
$blue_700: #102c60;
$grey_400: #afafaf;
$pink_500: #e72175;
$blue_900: #092148;
$blue_750: #0d3472;
$grey_250: #d9d9d9;
$grey_650: #454545;

$neutrals_2: #fcfcfc;
$neutrals_4: #f0f0f0;
$neutrals_5: #d9d9d9;
$grey_550: #8c8c8c;
$pink_20: #f9cade;
$pink_550: #c41c63;
$pink_450: #eb498e;
$grey_950: #212124;


$black_65: rgba(0, 0, 0, 0.65);

