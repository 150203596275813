@import 'scss/common';
@import 'scss/variables';

.dynamic {
  display: flex;
  margin: 0px 15px 15px 15px;
}

.configuration {
  width: 100%;
}

.editor {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 14px);
  margin: 7px 7px 7px 7px;
}

.grow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: $border;
  border: 1px solid $border;
  font-size: 30px;

  p {
    font-weight: 500;
    font-size: 13px;
    margin: 10px 0px;
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // border-right: 1px solid $border;
  border-left: 1px solid $border;
  min-width: 200px;
  max-width: 200px;

  .tab:first-child {
    border-top: 1px solid $border;
  }

  .tab:last-child {
    border-bottom: 0px solid $border;
  }

  .tab {
    display: flex;
    align-items: center;
    position: relative;
    flex-grow: 1;
    border-bottom: 1px solid $border;
    padding: 0px 15px;
    cursor: pointer;

    p {
      font-size: 13px;
      line-height: 13px;
      margin: 0px;
    }
  }

  .tab:hover {
    color: $hover;
  }

  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 16px;
    top: 3px;
    right: 3px;
    min-width: 16px;
    max-height: 16px;
    min-height: 16px;
    border: 1px solid darken($orange, 15%);
    background: lighten($orange, 35%);
    color: darken($orange, 15%);

    span {
      font-size: 10px;
      line-height: 9px;

      padding: 1px 3px 0px 3px;
      margin: 0px;
    }
  }

  .active {
    color: $hover;
  }
}

.feature {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  border-bottom: 1px solid $border;
}