@import 'src/scss/common';
@import 'src/scss/variables';

.chevron {
  margin: 0px 15px;
}

.relative {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 36px;
  width: 36px;
}
.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: $pink;
  position: fixed;
  height: 44px;
  width: 44px;
  right: 10px;
  bottom: 10px;
  color: #fff;
  border-radius: 44px;
  border: 1px solid darken($hover, 10%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  z-index: 10;
}
.circle:hover {
  background: lighten($pink, 5%);
}
.relative:hover [data-role='tooltip'] {
  display: block;
}
