@import 'src/scss/common';
@import 'src/scss/variables';

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  font-size: 11px;
  color: $icon;
}

.chip {
  display: inline-block;
  text-overflow: ellipsis;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  border-radius: 3px;
  margin: 0px;
  line-height: 12px;
  margin: 3px 2px;
  padding: 3px 5px 3px 4px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 3px 0px;
  padding: 0px 3px;
  font-size: 12px;
}

.icon:hover {
  opacity: 0.7;
}

.remove {
  padding-right: 20px;
}

.state .state {
  margin: 0px 4px 0px 2px;
  color: $silver;
  font-size: 10px;
}

.wrapper {
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.link {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 13px;
  min-width: 50px;
}

.link .state {
  margin: 0px 4px 0px 2px;
  color: $silver;
  font-size: 10px;
}

.action:hover {
  color: $hover;
}

.action:hover [data-role='tooltip'] {
  display: block;
}

.state:hover [data-role='tooltip'] {
  display: block;
}

.radio {
  font-size: 12px;
  color: $grey;
}

.chip.pink {
  border: 1px solid $pink;
  background: lighten($pink, 35%);
  color: $pink  !important;
}

.chip.orange {
  border: 1px solid darken($orange, 15%);
  background: lighten($orange, 35%);
  color: darken($orange, 15%) !important;
}

.chip.green {
  border: 1px solid darken($green, 10%);
  background: lighten($green, 35%);
  color: darken($green, 10%) !important;
}

.chip.blue {
  border: 1px solid $blue;
  background: lighten($blue, 35%);
  color: $blue  !important;
}

.chip.red {
  border: 1px solid darken($red, 10%);
  background: lighten($red, 35%);
  color: darken($red, 10%) !important;
}

.chip.silver {
  border: 1px solid $silver;
  background: lighten($silver, 35%);
  color: $silver  !important;
}

.pink {
  color: $pink  !important;
}

.orange {
  color: $orange  !important;
}

.green {
  color: $green  !important;
}

.blue {
  color: $blue  !important;
}

.red {
  color: $red  !important;
}

.silver {
  color: $silver  !important;
}

.grey {
  color: $grey  !important;
}