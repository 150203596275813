.public,
.private {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-width: 1350px;
  background: #fff;
}
.private {
  flex-direction: row;
  background: #f8f9fe;
}
.scrollable {
  position: relative;
  display: flex;
  flex-grow: 1;
}
.scroll > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.scroll > div:nth-child(2) {
  height: 0px !important;
}
.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

// body ::-webkit-scrollbar {
//   opacity: 0;
// }
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  width: calc(100% - 30px);
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.nav {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  padding-left: 40px;
  align-self: stretch;
  text-transform: uppercase;
}
.flex {
  flex-grow: 1;
}
.logo {
  width: 165px;
}
.flex {
  flex-grow: 1;
}
