@import 'scss/common';

.wrapper {
  display: flex;
  flex-grow: 1;
  align-self: stretch;
}
.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 15px;
}
.chartWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px 0px 0px 0px;
  h3 {
    margin: 0px;
    padding: 0px;
    font-size: 16px;
  }
}
.chart {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px 15px 15px 15px;
}
