@import 'scss/variables';

.warning {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 24px;
  padding: 5px 0px;
  color: $font;
  p {
    flex-grow: 1;
    margin: 0px;
    font-size: 13px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    text-align: center;
  }
}
