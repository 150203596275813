.wrapper {
  display: flex;
  flex-direction: column;
  outline: 0;
}
.dropdown:hover [data-role='tooltip'] {
  display: block;
}
.dropdown {
  position: relative;
  width: 100%;
}
.base {
  padding: 0px 5px;
  max-width: 400px;
  margin: 0;
  font-size: 13px;
  background: transparent;
  align-items: center;
  font-weight: inherit;
  font-family: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select {
  display: none;
  position: absolute;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid #e855b6;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  box-sizing: border-box;
  background: #ffffff;
  overflow: hidden;
  overflow-y: scroll;
  max-height: max-content;
  z-index: 3;
}
.flex {
  display: flex;
  align-items: center;
}
.icon {
  opacity: 0;
}
.dropdown:hover .select {
  display: block;
  opacity: 1;
}
.dropdown:hover .icon {
  color: '#e855b6';
  opacity: 1;
}
.autosizer {
  position: absolute;
  div > div:first-of-type {
    border-top: none;
  }
  div > div:last-of-type {
    border-bottom: none;
  }
}
.select::-webkit-scrollbar {
  width: 6px;
  background: #ffffff;
}
::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 4px;
}
.link {
  background: #fff;
  cursor: pointer;
  padding: 7px 10px;
  font-weight: 400;
  z-index: 2;
  color: #414043;
  height: 100%;
  flex-grow: 1;
}

.link:hover {
  color: #e855b6;
}

.pink {
  color: #e855b6 !important;
}

.page {
  display: flex;
  align-items: center;
  color: #414043;
}

.option {
  background: #fff;
  cursor: pointer;
  font-size: 13px;
  box-sizing: border-box;
  z-index: 2;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
