@import 'src/scss/variables';

.row {
  display: flex;
  position: relative;
  box-sizing: border-box;
}

.spacer {
  margin: 15px 10px;
  border-top: 1px solid $border;
  border-radius: 1px;
}
