@import 'src/scss/common';
@import 'src/scss/variables';

.overlay {
  z-index: 999;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  > div > iframe {
    position: initial;
  }
}
.overlayColor {
  background-color: rgba(0, 0, 0, 0.5);
}

.button {
  z-index: 9999;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 20px;
  color: white;
}
.relative {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 50px;
}
.pink {
  color: #e855b6;
  z-index: 9999;
  cursor: pointer;
}

.preview {
  width: 850px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    display: none;
  }
}
