* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

body {
  position: fixed;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

a {
  color: #e855b6;
  font-weight: 400;
  font-size: 13px;
  padding: 0px 5px;
  text-decoration: none;
}

a:hover {
  color: lighten(#e855b6, 10);
}

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 7px;
}

.table {
  display: flex;
  flex-grow: 1;
  margin: 0px 15px 15px 15px;
}

.flex {
  flex-grow: 1;
}

.break {
  border-right: 1px solid #b5bdc1;
  margin: 15px 5px;
}

.verticalBreak {
  border-bottom: 1px solid #b5bdc1;
  margin: 15px 0px;
}

input[type='checkbox'] {
  flex-grow: unset;
}
