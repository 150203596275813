@import 'src/scss/common';
@import 'src/scss/variables';

h3 {
  font-weight: 400;
  font-size: 16px;
  padding: 0px 0px 20px 0px;
}

.modalMessage {
  padding: 15px;
  padding-top: 0px;

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;

    .icon {
      font-size: 48px;
      color: $font;
    }
  }

  .text {
    text-align: left;
    margin: 0px;
    font-weight: 400;
    font-size: 14px;
    padding: 15px 0px;
    color: $font;

    &.center {
      text-align: center;
    }

    &.small {
      font-size: 13px;
    }
  }
}

.errorModal {
  color: $font;

  .warning {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px 0px 0px;
  }

  .icon {
    font-size: 48px;
    color: $font;
  }

  .status {
    margin: 10px 0px 0px 0px;
    font-size: 18px;
    font-weight: 600;
  }

  .text {
    text-align: left;
    margin: 0px;
    font-weight: 400;
    font-size: 14px;
    padding: 20px 0px 0px 0px;
    color: $font;
  }
}

.buttonContainer {
  display: flex;
  margin-top: 20px;
  width: 100%;
}

.chartWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  h3 {
    font-weight: 400;
    font-size: 16px;
    padding: 20px 20px 10px 20px;
  }
}

.chart {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px 0px 0px 0px;
}

.pink {
  color: $pink !important;
}

.orange {
  color: $orange !important;
}

.green {
  color: $green !important;
}

.jsonDiff {
  border: 1px solid $border;
  min-height: 100px;
  max-height: 500px;
  margin: 0px 5px 0px 5px;
  overflow: scroll;
}

.chart {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 15px;
  background: #fff;
  min-height: 350px;
  max-height: 350px;
}

.filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  margin-top: 15px;
  padding-top: 15px;
  min-height: 250px;
}

.grow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: $border;
  font-size: 30px;

  p {
    font-weight: 500;
    font-size: 13px;
    margin: 10px 0px;
  }
}

.editor {
  margin: 10px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tableFlex {
  display: flex;
  flex-grow: 1;
  min-height: 500px;
  margin: 0px 15px;
}

.header {
  font-size: 16px;
  font-weight: 400;
  padding: 16px;
  margin: 0;
  color: black;
  text-align: left;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0px 32px 0px 0px;
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
}

.cancel:hover {
  color: $pink;
}
