@import 'scss/common';
@import 'scss/variables';

.creative {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 286px;
  height: 200px;
  transform-origin: 50% 0%;
  svg {
    position: absolute;
  }
}
.creative:hover {
  svg {
    color: $pink;
  }
}
.creative iframe {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.creativeTop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 286px;
  height: 200px;
  transform-origin: 50% 0%;
}
.creativeTop iframe {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
}
