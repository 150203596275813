@import 'scss/variables';

.loading {
  z-index: 999;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.loading.dark {
  background-color: rgba(0, 0, 0, 0.5);
}
.loading.light {
  background-color: $menu;
}

.spinner {
  animation: spin 1.75s linear infinite;
  height: 45px;
  width: 45px;
}

.spinnerSmall {
  animation: spin 1.75s linear infinite;
  height: 25px;
  width: 25px;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
