@import 'scss/common';
@import 'scss/variables';

.entities {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 50px;
  max-width: 50px;
  background: $menu;
  border-right: 1px solid #6e7582;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  z-index: 999;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 47px;
    max-height: 47px;
    min-width: 47px;
    max-width: 47px;
    padding: 0px;

    img {
      min-width: 47px;
      max-width: 47px;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-top: 1px solid #6e7582;
    padding-top: 15px;
    min-width: 50px;
    max-width: 50px;
  }

  .entity {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    position: relative;
    cursor: pointer;
    min-height: 44px;
    max-height: 44px;
    text-align: center;
    color: #f9f8fe;
    font-size: 15px;
  }

  .entity:hover {
    color: $pink;
  }

  .entity.active {
    color: $pink;
  }

  .entity:hover [data-role='tooltip'] {
    display: block;
  }
}

.navigation {
  display: flex;
  align-items: center;
  color: $font;
  background: #fff;
  border-bottom: 1px solid #b6bac0;
  box-sizing: border-box;
  min-height: 48px;
  max-height: 48px;
  padding: 0px 0px 0px 0px;
  z-index: 5;

  .ellipsis {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    text-align: center;
    position: relative;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 12px;
  }

  .ellipsis:hover {
    color: $hover;
  }
}

.nav {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-grow: 1;
  padding-left: 10px;
}

.wrapper {
  display: flex;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
}

.flex {
  flex-grow: 1;
}

.chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: stretch;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  padding: 0px 5px;
  margin: 0px 0px 0px 15px;
}

.parameters {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: stretch;
  text-align: center;
  cursor: pointer;
  font-size: 11px;
  padding: 0px 5px;
}

.space {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  cursor: pointer;
  color: $font;
  font-size: 13px;
  padding: 0px 5px 0px 20px;
  letter-spacing: 0.5px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.items {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-grow: 1;
}

.item {
  cursor: pointer;
  color: #e855b6;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  text-transform: none;
  font-weight: 400;
  font-size: 13px;
  padding: 0px 5px;
  margin: 0px;
}

.item:hover {
  color: $hover;
}

.page {
  cursor: pointer;
  color: #1d1d1d;
  font-family: 'Roboto', sans-serif;
  text-transform: capitalize;
  max-width: max-content;
  font-weight: 400;
  font-size: 13px;
  padding: 0px 5px 0px 10px;
  margin: 0px;
}

.page:hover {
  color: #1d1d1d;
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  position: relative;
  cursor: pointer;
  color: $icon;
  border-left: 1px solid #b6bac0;
  min-width: 49px;
  max-width: 49px;
  box-sizing: border-box;
  font-size: 14px;
}

.action:hover {
  color: $hover;
}

.pink {
  color: $pink !important;
}

.orange {
  color: $orange !important;
}

.green {
  color: $green !important;
}

.blue {
  color: $blue !important;
}

.red {
  color: $red !important;
}

.silver {
  color: $silver !important;
}

.icon {
  margin-left: 10px;
  margin-right: 12px;
  font-size: 13px;
}